// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue, Button, Input } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";
import StatusMessage from "components/StatusMessage"; // Ensure you have this component in the correct path

export default function Banner(props) {
  const { banner, avatar, username, firstName, lastName, email, created_at } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [newFirstName, setNewFirstName] = useState(firstName);
  const [newLastName, setNewLastName] = useState(lastName);
  const [newEmail, setNewEmail] = useState(email);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    setNewFirstName(firstName);
    setNewLastName(lastName);
    setNewEmail(email);
  }, [firstName, lastName, email]);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue("white !important", "#111C44 !important");

  const handleSave = async () => {
    const confirmed = window.confirm("Are you sure you want to update your information?");
    if (!confirmed) return;

    setStatus({ status: "loading", message: "Updating..." });

    try {
      const response = await fetch("/update_user", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ firstName: newFirstName, lastName: newLastName, email: newEmail }),
      });
      
      const result = await response.json();
      if (response.ok) {
        setStatus({ status: "success", message: "User updated successfully" });
        setIsEditing(false);
      } else {
        throw new Error(result.details);
      }
    } catch (error) {
      setStatus({ status: "error", message: `An error occurred: ${error.message}` });
    }
  };

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="center" position="relative">
      <Box bg={`url(${banner})`} bgSize="cover" borderRadius="16px" h="131px" w="100%" position="relative">
        <Button
          position="absolute"
          top="10px"
          right="10px"
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ? "Cancel" : "Edit"}
        </Button>
      </Box>
      <Avatar mx="auto" src={avatar} h="87px" w="87px" mt="-43px" border="4px solid" borderColor={borderColor} />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {username}
      </Text>
      <Flex direction="column" align="center" mt="26px">
        <Flex direction="row" align="center" mb="10px">
          <Flex direction="column" align="center" mr="10px">
            <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
              First Name
            </Text>
            {isEditing ? (
              <Input value={newFirstName} onChange={(e) => setNewFirstName(e.target.value)} />
            ) : (
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {firstName}
              </Text>
            )}
          </Flex>
          <Flex direction="column" align="center">
            <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
              Last Name
            </Text>
            {isEditing ? (
              <Input value={newLastName} onChange={(e) => setNewLastName(e.target.value)} />
            ) : (
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {lastName}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex direction="column" align="center" mb="10px">
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Email
          </Text>
          {isEditing ? (
            <Input value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
          ) : (
            <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
              {email}
            </Text>
          )}
        </Flex>
        <Flex direction="column" align="center" mb="10px">
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Member Since
          </Text>
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {new Date(created_at).toLocaleDateString()}
          </Text>
        </Flex>
        {isEditing && (
          <Button mt="10px" colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        )}
      </Flex>
      {status && (
        <StatusMessage
          status={status.status}
          message={status.message}
          onClose={() => setStatus(null)}
        />
      )}
    </Card>
  );
}

import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { MdAttachMoney, MdBarChart, MdError } from "react-icons/md";
import axios from "axios";
import config from "config";
import ItemsTable from "./components/ItemsTable";
import EditItemModal from "./components/EditItemModal";
import UncategorizedItemsModal from "./components/UncategorizedItemsModal";
import FilterPanel from "./components/FilterPanel";

export default function ItemsIndex() {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  // States for managing data
  const [items, setItems] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [uncategorizedItems, setUncategorizedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUncategorizedModalOpen, setUncategorizedModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filters] = useState({
    dateFilterType: "last30days", // Default to last 30 days
  });

  // Helper function to calculate date range for filters
  const calculateDateRange = useCallback((filterType) => {
    const now = new Date();
    let startDate = new Date();

    if (filterType === "last7days") {
      startDate.setDate(now.getDate() - 7);
    } else if (filterType === "last30days") {
      startDate.setDate(now.getDate() - 30);
    } else if (filterType === "last90days") {
      startDate.setDate(now.getDate() - 90);
    }

    return [startDate, now];
  }, []);

  // Function to apply filters to the data
  const applyFilters = useCallback((newFilters, itemsToFilter, receiptsToFilter) => {
    let [startDate, endDate] = calculateDateRange(newFilters.dateFilterType);

    // Filter logic based on user selections
    const filtered = itemsToFilter.filter((item) => {
      const receipt = receiptsToFilter.find((r) => r.id === item.receipt_id);
      if (!receipt) return false;

      const matchesStore = newFilters.store ? receipt.merchant_name.toLowerCase().includes(newFilters.store.toLowerCase()) : true;
      const matchesCategory = newFilters.category ? item.category === newFilters.category : true;
      const matchesName = newFilters.itemName ? item.description.toLowerCase().includes(newFilters.itemName.toLowerCase()) : true;

      let matchesDate = true;
      if (startDate && endDate) {
        const receiptDate = new Date(receipt.transaction_date);
        matchesDate = receiptDate >= startDate && receiptDate <= endDate;
      }

      return matchesStore && matchesCategory && matchesName && matchesDate;
    });

    // Update state with filtered data
    setFilteredItems(filtered);

    // Update mini statistics
    const filteredUncategorized = filtered.filter((item) => item.category === "Uncategorized");

    setUncategorizedItems(filteredUncategorized);
  }, [calculateDateRange]);

  // Function to fetch items and receipts when the component mounts
  const fetchItemsAndReceipts = useCallback(async () => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem("token");

      // Fetch both items and receipts using Promise.all
      const [itemsResponse, receiptsResponse] = await Promise.all([
        axios.get(`${config.API_BASE_URL}/get_all_items`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.API_BASE_URL}/get_all_receipts`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const items = itemsResponse.data.items;
      const receipts = receiptsResponse.data.receipts;

      // Set fetched items and receipts in state
      setItems(items);
      setReceipts(receipts);

      // Apply filters with the fetched data
      applyFilters(filters, items, receipts);
    } catch (error) {
      console.error("Error fetching items and receipts:", error);
    } finally {
      setIsLoading(false);
    }
  }, [filters, applyFilters]);

  // Fetch data on initial mount
  useEffect(() => {
    fetchItemsAndReceipts();
  }, [fetchItemsAndReceipts]);

  // Handle opening and closing of modals
  const handleUncategorizedClick = () => {
    setUncategorizedModalOpen(true);
  };

  const handleViewItemClick = (item) => {
    setSelectedItem(item);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setEditModalOpen(false);
  };

  const handleCloseUncategorizedModal = () => {
    setUncategorizedModalOpen(false);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex justify="flex-start" mb="20px">
        <Button onClick={fetchItemsAndReceipts} colorScheme="blue" variant="solid" mr="10px">
          Refresh
        </Button>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mb="20px">
        <Box w="100%" h="100%" display="flex">
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<MdBarChart color={brandColor} />} />
            }
            name="Total Items"
            value={filteredItems.length}
          />
        </Box>
        <Box w="100%" h="100%" display="flex">
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<MdAttachMoney color={brandColor} />} />
            }
            name="Total Spent"
            value={filteredItems.reduce((sum, item) => sum + parseFloat(item.total_price), 0).toFixed(2)}
          />
        </Box>
        <Box w="100%" h="100%" display="flex" onClick={handleUncategorizedClick} cursor="pointer">
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<MdError color={brandColor} />} />
            }
            name="Uncategorized Items"
            value={uncategorizedItems.length}
          />
        </Box>
      </SimpleGrid>
      <FilterPanel applyFilters={(filters) => applyFilters(filters, items, receipts)} items={items} />
      <ItemsTable
        items={filteredItems}
        receipts={receipts}
        onViewItem={handleViewItemClick}
        fetchItemsAndReceipts={fetchItemsAndReceipts}
        filters={filters}
      />
      {selectedItem && (
        <EditItemModal
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          item={selectedItem}
          receipts={receipts}
          fetchItemsAndReceipts={fetchItemsAndReceipts}
        />
      )}
      <UncategorizedItemsModal
        isOpen={isUncategorizedModalOpen}
        onClose={handleCloseUncategorizedModal}
        items={uncategorizedItems}
        onEditItem={handleViewItemClick}
      />
      {isLoading && <Spinner />}
    </Box>
  );
}

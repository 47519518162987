import React, { useEffect, useRef } from 'react';
import { Box, Text } from '@chakra-ui/react';
import config from 'config'; // Adjust the path as needed

const Feedback = () => {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="20px"
      textAlign="center"
      mb="20px"
    >
      <Text fontSize="2xl" mb="4">
        We Value Your Feedback!
      </Text>
      <Text fontSize="lg" mb="4">
        Please send your feedback to <strong>{config.FEEDBACK_EMAIL}</strong>.
      </Text>
    </Box>
  );
};

export default Feedback;

import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
  useDisclosure,
  Input,
  FormControl,
  FormErrorMessage,
  Select,
  Flex,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from 'uuid';  // For generating new item IDs
import axios from 'axios';
import config from 'config';  // Assuming config contains API_BASE_URL

function ItemsModal({ isOpen, onClose, items, onItemClick }) {
  const [sortConfig, setSortConfig] = useState({ key: 'category', direction: 'ascending' });
  const { isOpen: isEditModalOpen, onOpen: openEditModal, onClose: closeEditModal } = useDisclosure();
  const [currentItem, setCurrentItem] = useState(null);  // Holds current item for editing
  const [editedItem, setEditedItem] = useState(null);  // For form input values
  const [errors] = useState({});  // To store validation errors
  const [isSaving, setIsSaving] = useState(false);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Function to handle saving an item (add or update)
  const handleSaveItem = async () => {
    setIsSaving(true);
    try {
      const token = localStorage.getItem("token");
      const itemToSave = {
        ...editedItem,
        total_price: parseFloat(editedItem.total_price).toFixed(2),  // Ensure 2 decimal places when saving
      };

      if (currentItem && currentItem.id) {
        // If the item exists, update it
        await axios.patch(`${config.API_BASE_URL}/update_item/${editedItem.id}`, itemToSave, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else {
        // Otherwise, create a new item
        await axios.post(`${config.API_BASE_URL}/add_item`, { ...itemToSave, id: uuidv4() }, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }

      onItemClick();  // Refresh the list of items
      closeEditModal();  // Close the modal after save
    } catch (error) {
      console.error("Error saving item:", error);
    } finally {
      setIsSaving(false);
    }
  };

  // Function to delete an item
  const handleDeleteItem = async () => {
    if (!currentItem || !currentItem.id) return;

    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        const token = localStorage.getItem("token");
        await axios.delete(`${config.API_BASE_URL}/delete_item/${currentItem.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        onItemClick();  // Refresh the list of items
        closeEditModal();  // Close the modal after deletion
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  // Opens the modal with the selected item's data for editing
  const handleEditItem = (item) => {
    setCurrentItem(item);
    setEditedItem({
      ...item,
      total_price: parseFloat(item.total_price).toFixed(2),  // Ensure 2 decimal places
      quantity: parseFloat(item.quantity).toFixed(2),  // Optional: format quantity if needed
    });
    openEditModal();
  };

  // Opens the modal for adding a new item
  const handleAddItem = () => {
    const newItem = {
      id: uuidv4(),
      description: "",
      quantity: 1,
      total_price: "0.00",
      category: "",
    };
    setCurrentItem(null);
    setEditedItem(newItem);  // Set default values for new item
    openEditModal();
  };

  // Handle input change for item form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedItem((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', md: 'lg' }}>
        <ModalOverlay />
        <ModalContent maxW={{ base: '100%', md: '600px' }}>
          <ModalHeader>All Items</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" maxH="80vh">
            <Box mb={4}>
              {/* Add Item Button */}
              <Button colorScheme="teal" onClick={handleAddItem}>
                Add Item
              </Button>
            </Box>
            <Box overflowX={{ base: 'auto', md: 'initial' }}>
              {items.length > 0 ? (
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th p="2" whiteSpace="normal" onClick={() => requestSort('category')}>Category</Th>
                      <Th p="2" whiteSpace="normal" onClick={() => requestSort('description')}>Description</Th>
                      <Th p="2" whiteSpace="normal" onClick={() => requestSort('quantity')}>Quantity</Th>
                      <Th p="2" whiteSpace="normal" onClick={() => requestSort('total_price')}>Total Price</Th>
                    </Tr>
                  </Thead>
                  <Tbody fontSize="sm">
                    {sortedItems.map((item) => (
                      <Tr key={item.id} onClick={() => handleEditItem(item)} cursor="pointer">
                        <Td p="2">{item.category || 'Unknown'}</Td>
                        <Td p="2">{item.description || 'No Description'}</Td>
                        <Td p="2">{item.quantity}</Td>
                        <Td p="2">${parseFloat(item.total_price).toFixed(2)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Text>No items available</Text>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Item Editing Modal */}
      <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
        <ModalOverlay />
        <ModalContent maxW="400px">
          <ModalHeader>{currentItem ? 'Edit Item' : 'Add Item'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4} isInvalid={!!errors.description}>
              <Input
                placeholder="Description"
                name="description"
                value={editedItem?.description || ''}
                onChange={handleInputChange}
              />
              <FormErrorMessage>{errors.description}</FormErrorMessage>
            </FormControl>
            <FormControl mb={4}>
              <Input
                placeholder="Quantity"
                name="quantity"
                value={editedItem?.quantity || 1}
                type="number"
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <Input
                placeholder="Total Price"
                name="total_price"
                value={editedItem?.total_price || '0.00'}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <Select
                placeholder="Category"
                name="category"
                value={editedItem?.category || ''}
                onChange={handleInputChange}
              >
                <option value="Fruits and Vegetables">Fruits and Vegetables</option>
                <option value="Canned Goods">Canned Goods</option>
                <option value="Dairy">Dairy</option>
                <option value="Meat">Meat</option>
                <option value="Fish and Seafood">Fish and Seafood</option>
                <option value="Deli">Deli</option>
                <option value="Condiments and Sauces">Condiments and Sauces</option>
                <option value="Snacks">Snacks</option>
                <option value="Breads and Bakery">Breads and Bakery</option>
                <option value="Beverages">Beverages</option>
                <option value="Frozen Foods">Frozen Foods</option>
                <option value="Grains, Pasta and Sides">Grains, Pasta and Sides</option>
                <option value="Baking">Baking</option>
                <option value="Personal Care">Personal Care</option>
                <option value="Household and Cleaning">Household and Cleaning</option>
                <option value="Health">Health</option>
                <option value="Baby">Baby</option>
                <option value="Pets">Pets</option>
                <option value="Alcohol">Alcohol</option>
                <option value="Uncategorized">Uncategorized</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex justify="space-between" w="100%">
              <Button colorScheme="red" onClick={handleDeleteItem} isDisabled={!currentItem}>Delete</Button>
              <Button colorScheme="blue" onClick={handleSaveItem} isLoading={isSaving}>
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ItemsModal;

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import UserLayout from 'layouts/user';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import axios from 'axios';
import config from 'config';

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <ThemeEditorProvider>
          <HashRouter>
            <AuthenticationHandler />
          </HashRouter>
        </ThemeEditorProvider>
      </React.StrictMode>
    </ChakraProvider>
  );
};

const AuthenticationHandler = () => {
  const [authenticated, setAuthenticated] = useState(null);
  const history = useHistory();

  const checkAuth = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log("No token found");
      return false;
    }

    try {
      const headers = { Authorization: `Bearer ${token}` };
      await axios.get(`${config.API_BASE_URL}/get_user`, { headers });
      console.log("User authenticated");
      return true;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Token invalid or expired");
        return false;
      }
      console.error("Error during authentication check", error);
      throw error;
    }
  };

  useEffect(() => {
    const authenticate = async () => {
      const authStatus = await checkAuth();
      setAuthenticated(authStatus);
      if (!authStatus) {
        history.push('/auth/sign-in');
      }
    };
  
    if (authenticated === null) {
      authenticate();
    } else if (authenticated) {
      history.push('/user/default');  // Ensure this redirects to the admin page after successful login
    }
  }, [authenticated, history]); // Add `authenticated` to the dependency array
  

  if (authenticated === null) {
    return <div>Loading...</div>; // Show a loading spinner or some placeholder
  }

  return (
    <Switch>
      <Route path={`/auth`} component={AuthLayout} />
      {authenticated ? (
        <Route path={`/user`} component={UserLayout} />
      ) : (
        <Redirect to='/auth/sign-in' />
      )}
      <Redirect from='/' to='/user/default' />
    </Switch>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Box,
  Flex,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { MdAttachMoney, MdBarChart, MdError } from "react-icons/md";
import axios from "axios";
import ReceiptsTable from "views/user/receipts/components/ReceiptsTable";
import UploadButton from "views/user/receipts/components/UploadButton";
import InvalidReceiptsModal from "views/user/receipts/components/InvalidReceiptsModal";
import EditReceiptModal from "views/user/receipts/components/EditReceiptModal";
import FilterPanel from "views/user/receipts/components/FilterPanel";
import config from "config";

export default function ReceiptsIndex() {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [receipts, setReceipts] = useState([]);
  const [totalSpendings, setTotalSpendings] = useState(0);
  const [invalidReceiptsCount, setInvalidReceiptsCount] = useState(0);
  const [isInvalidModalOpen, setInvalidModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [allItems, setAllItems] = useState([]);
  const [filteredReceipts, setFilteredReceipts] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    dateFilterType: "last30days", // Default filter to last 30 days
  });

  const calculateDateRange = useCallback((filterType) => {
    const now = new Date();
    let startDate = new Date();

    if (filterType === "last7days") {
      startDate.setDate(now.getDate() - 7);
    } else if (filterType === "last30days") {
      startDate.setDate(now.getDate() - 30);
    } else if (filterType === "last90days") {
      startDate.setDate(now.getDate() - 90);
    }

    return [startDate, now];
  }, []);

  const applyFilters = useCallback((newFilters, receiptsToFilter = receipts) => {
    setFilters(newFilters);

    let [startDate, endDate] = [null, null];
    if (newFilters.dateFilterType !== "custom") {
      [startDate, endDate] = calculateDateRange(newFilters.dateFilterType);
    } else {
      if (newFilters.customDateType === "specific") {
        startDate = new Date(newFilters.specificDate);
        endDate = new Date(newFilters.specificDate);
      } else if (newFilters.customDateType === "before") {
        endDate = new Date(newFilters.relativeDate);
      } else if (newFilters.customDateType === "after") {
        startDate = new Date(newFilters.relativeDate);
      } else if (newFilters.customDateType === "between") {
        startDate = newFilters.startDate ? new Date(newFilters.startDate) : null;
        endDate = newFilters.endDate ? new Date(newFilters.endDate) : null;
      }
    }

    const filtered = receiptsToFilter.filter((receipt) => {
      const matchesStore = newFilters.store ? receipt.merchant_name.toLowerCase().includes(newFilters.store.toLowerCase()) : true;
      const matchesStatus = newFilters.status ? String(receipt.validation_status) === newFilters.status : true;

      let matchesDate = true;
      if (startDate && endDate) {
        const receiptDate = new Date(receipt.transaction_date);
        matchesDate = receiptDate >= startDate && receiptDate <= endDate;
      }

      return matchesStore && matchesStatus && matchesDate;
    });

    setFilteredReceipts(filtered);

    // Update mini statistics
    const filteredTotalSpendings = filtered.reduce((sum, receipt) => sum + parseFloat(receipt.total), 0);
    const filteredInvalidCount = filtered.filter(receipt => !receipt.validation_status).length;

    setTotalSpendings(filteredTotalSpendings.toFixed(2));
    setInvalidReceiptsCount(filteredInvalidCount);
  }, [calculateDateRange, receipts]);

  const fetchReceiptsAndItems = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");

      const [receiptsResponse, itemsResponse] = await Promise.all([
        axios.get(`${config.API_BASE_URL}/get_all_receipts`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.API_BASE_URL}/get_all_items`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const receipts = receiptsResponse.data.receipts;
      const items = itemsResponse.data.items;

      setReceipts(receipts);
      setAllItems(items);

      applyFilters(filters, receipts); // Apply filters initially
    } catch (error) {
      console.error("Error fetching receipts and items:", error);
    }
  }, [filters, applyFilters]);

  useEffect(() => {
    fetchReceiptsAndItems();
  }, [fetchReceiptsAndItems]);

  const handleInvalidReceiptsClick = () => {
    setInvalidModalOpen(true);
  };

  const handleViewReceiptClick = (receipt) => {
    setIsLoading(true);
    setSelectedReceipt(receipt);
    const filtered = allItems.filter(item => item.receipt_id === receipt.id);
    setFilteredItems(filtered);
    setIsLoading(false);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedReceipt(null);
    setFilteredItems([]); // Clear filtered items when closing
    setEditModalOpen(false);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex justify="flex-start" mb="20px">
        <Button onClick={fetchReceiptsAndItems} colorScheme="blue" variant="solid" mr="10px">
          Refresh
        </Button>
        <UploadButton />
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mb="20px">
        <Box w="100%" h="100%" display="flex">
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<MdBarChart color={brandColor} />} />
            }
            name="Number of Receipts"
            value={filteredReceipts.length}
          />
        </Box>
        <Box w="100%" h="100%" display="flex">
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<MdAttachMoney color={brandColor} />} />
            }
            name="Total Spent"
            value={`$${totalSpendings}`}
          />
        </Box>
        <Box 
          w="100%" 
          h="100%" 
          display="flex" 
          onClick={handleInvalidReceiptsClick} 
          cursor="pointer"
        >
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<MdError color={brandColor} />} />
            }
            name="Invalid Receipts"
            value={invalidReceiptsCount}
          />
        </Box>
      </SimpleGrid>
      <FilterPanel applyFilters={(filters) => applyFilters(filters, receipts)} receipts={receipts} />
      <ReceiptsTable
        receipts={filteredReceipts}
        onViewReceipt={handleViewReceiptClick}
        fetchReceiptsAndItems={fetchReceiptsAndItems}
        filters={filters}
      />

      {isLoading ? (
        <Spinner />
      ) : (
        selectedReceipt && (
          <EditReceiptModal
            isOpen={isEditModalOpen}
            onClose={handleCloseEditModal}
            receipt={selectedReceipt}
            allItems={filteredItems}
            fetchReceiptsAndItems={fetchReceiptsAndItems}
          />
        )
      )}

      <InvalidReceiptsModal
        isOpen={isInvalidModalOpen}
        onClose={() => setInvalidModalOpen(false)}
        receipts={receipts}
        onEditReceipt={handleViewReceiptClick}
      />
    </Box>
  );
}

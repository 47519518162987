import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import ItemsModal from './ItemsModal';

function ReceiptsModal({ isOpen, onClose, receipts, items, selectedReceiptId }) {
  const [isItemsModalOpen, setItemsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'merchant_name', direction: 'ascending' });
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleOpenItemsModal = (receiptId) => {
    const receiptItems = items.filter(item => item.receipt_id === receiptId);
    setSelectedItems(receiptItems);
    setItemsModalOpen(true);
  };

  const handleCloseItemsModal = () => {
    setItemsModalOpen(false);
    setSelectedItems([]);
  };

  const filteredReceipts = selectedReceiptId
    ? receipts.filter(receipt => receipt.id === selectedReceiptId)
    : receipts;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', md: 'lg' }}>  {/* Use lg size for medium screens */}
        <ModalOverlay />
        <ModalContent maxW={{ base: '100%', md: '800px' }}>  {/* Full width on mobile, max 800px on larger screens */}
          <ModalHeader>{selectedReceiptId ? 'Receipt Details' : 'All Receipts'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" maxH="80vh">
            <Box overflowX={{ base: 'auto', md: 'initial' }}>
              {filteredReceipts.length > 0 ? (
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th p="2" whiteSpace="normal" onClick={() => requestSort('merchant_name')}>Merchant Name</Th>
                      <Th p="2" whiteSpace="normal" onClick={() => requestSort('transaction_date')}>Transaction Date</Th>
                      <Th p="2" whiteSpace="normal" onClick={() => requestSort('total')}>Total</Th>
                      <Th p="2" whiteSpace="normal" onClick={() => requestSort('validation_status')}>Validation Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody fontSize="sm">
                    {filteredReceipts.map((receipt) => (
                      <Tr key={receipt.id} onClick={() => handleOpenItemsModal(receipt.id)} cursor="pointer">
                        <Td p="2">{receipt.merchant_name || 'Unknown'}</Td>
                        <Td p="2">{new Date(receipt.transaction_date).toLocaleDateString()}</Td>
                        <Td p="2">${parseFloat(receipt.total).toFixed(2)}</Td>
                        <Td p="2">
                          {receipt.validation_status ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Text>No receipts available</Text>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ItemsModal isOpen={isItemsModalOpen} onClose={handleCloseItemsModal} items={selectedItems} onItemClick={() => {}} />
    </>
  );
}

export default ReceiptsModal;

import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import React from "react";
// Assets
import { MdOutlineCloudDone } from "react-icons/md";

export default function Usage(props) {
  const { used, total } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="center" justifyContent="center">
      <Flex direction="column" align="center" justify="flex-start" h="100%" mt="20%">
        <IconBox
          mx="auto"
          h="100px"
          w="100px"
          icon={
            <Icon as={MdOutlineCloudDone} color={brandColor} h="46px" w="46px" />
          }
          bg={box}
        />
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px">
          Your usage
        </Text>
        <Text
          color={textColorSecondary}
          fontSize="md"
          maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
          mx="auto"
          textAlign="center"
        >
          See your usage here
        </Text>
        <Box w="100%" mt="10px">
          <Flex w="100%" justify="space-between" mb="10px">
            <Text color={textColorSecondary} fontSize="sm" maxW="40%">
              {used} Receipts
            </Text>
            <Text color={textColorSecondary} fontSize="sm" maxW="40%">
              {total} Receipts
            </Text>
          </Flex>
          <Progress
            align="start"
            colorScheme="brandScheme"
            value={(used / total) * 100}
            w="100%"
          />
        </Box>
      </Flex>
    </Card>
  );
}

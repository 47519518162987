import React, { useMemo, useState, useCallback } from "react";
import {
  Table, Thead, Tbody, Tr, Th, Td, Box, Flex, Button,
} from "@chakra-ui/react";
import { useTable, useSortBy } from "react-table";
import axios from "axios";
import config from "config";
import StatusMessage from "components/StatusMessage";

const ItemsTable = ({ items, receipts, onViewItem, fetchItemsAndReceipts, filters }) => {
  const [status, setStatus] = useState({ type: null, message: "" });

  const handleDeleteItem = useCallback(async (itemId, event) => {
    event.stopPropagation();
    setStatus({ type: "loading", message: "Deleting item..." });
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${config.API_BASE_URL}/delete_item/${itemId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchItemsAndReceipts();
      setStatus({ type: "success", message: "Item deleted successfully." });
    } catch (error) {
      console.error("Error deleting item:", error);
      setStatus({ type: "error", message: "Failed to delete item." });
    }
  }, [fetchItemsAndReceipts]); // Memoized handleDeleteItem with useCallback

  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      const receipt = receipts.find(r => r.id === item.receipt_id);
      const matchesStore = filters.store ? receipt.merchant_name && receipt.merchant_name.toLowerCase().includes(filters.store.toLowerCase()) : true;
      const matchesCategory = filters.category ? item.category === filters.category : true;
      const matchesName = filters.itemName ? item.description.toLowerCase().includes(filters.itemName.toLowerCase()) : true;
      return matchesStore && matchesCategory && matchesName;
    });
  }, [items, receipts, filters]); // Added all necessary dependencies

  const columns = useMemo(
    () => [
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ row }) => {
          const receipt = receipts.find(r => r.id === row.original.receipt_id);
          return receipt ?  receipt.merchant_name : "Unknown";
        }
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row }) => {
          const receipt = receipts.find(r => r.id === row.original.receipt_id);
          return receipt ? new Date(receipt.transaction_date).toLocaleDateString() : "Unknown";
        }
      },
      {
        Header: "Item Name",
        accessor: "description",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        isNumeric: true,
      },
      {
        Header: "Price",
        accessor: "total_price",
        isNumeric: true,
        Cell: ({ cell: { value } }) => `$${parseFloat(value).toFixed(2)}`,
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <Flex justify="center" align="center">
            <Button size="sm" onClick={() => onViewItem(row.original)}>Edit</Button>
            <Button size="sm" colorScheme="red" ml={2} onClick={(e) => handleDeleteItem(row.original.id, e)}>Delete</Button>
          </Flex>
        ),
      },
    ],
    [receipts, onViewItem, handleDeleteItem] // Added all necessary dependencies
  );

  const data = useMemo(() => filteredItems, [filteredItems]); // Added dependency

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <Box overflowX="auto">
      {status.type && (
        <StatusMessage
          status={status.type}
          message={status.message}
          onClose={() => setStatus({ type: null, message: "" })}
        />
      )}
      <Table {...getTableProps()} variant="simple" colorScheme="teal">
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())} textAlign="center">
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} onClick={() => onViewItem(row.original)} cursor="pointer">
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()} textAlign="center">
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ItemsTable;

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

const UncategorizedItemsModal = ({ isOpen, onClose, items, onEditItem }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Uncategorized Items</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Table>
          <Thead>
            <Tr>
              <Th>Item Name</Th>
              <Th>Price</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.filter(item => item.category === "Uncategorized").map((item) => (
              <Tr key={item.id}>
                <Td>{item.description}</Td>
                <Td>${parseFloat(item.total_price).toFixed(2)}</Td>
                <Td>
                  <Button size="sm" onClick={() => onEditItem(item)}>Edit</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default UncategorizedItemsModal;

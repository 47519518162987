import React, { useMemo, useState, useCallback } from "react";
import {
  Table, Thead, Tbody, Tr, Th, Td, TableCaption, Box, Flex, Text, Button,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { useTable, useSortBy } from "react-table";
import axios from "axios";
import config from "config";
import StatusMessage from "components/StatusMessage";

const ReceiptsTable = ({ receipts, onViewReceipt, fetchReceiptsAndItems, filters }) => {
  const [status, setStatus] = useState({ type: null, message: "" });

  const filteredReceipts = useMemo(() => {
    return receipts.filter((receipt) => {
      const matchesStore = filters.store
        ? receipt.merchant_name && receipt.merchant_name.toLowerCase().includes(filters.store.toLowerCase())
        : true;

      let matchesDate = true;
      if (filters.dateFilterType === "specific" && filters.specificDate) {
        matchesDate = new Date(receipt.transaction_date).toLocaleDateString() === new Date(filters.specificDate).toLocaleDateString();
      } else if (filters.dateFilterType === "between" && filters.startDate && filters.endDate) {
        const receiptDate = new Date(receipt.transaction_date);
        matchesDate = receiptDate >= new Date(filters.startDate) && receiptDate <= new Date(filters.endDate);
      } else if (filters.dateFilterType === "before" && filters.relativeDate) {
        matchesDate = new Date(receipt.transaction_date) < new Date(filters.relativeDate);
      } else if (filters.dateFilterType === "after" && filters.relativeDate) {
        matchesDate = new Date(receipt.transaction_date) > new Date(filters.relativeDate);
      }

      const matchesStatus = filters.status ? String(receipt.validation_status) === filters.status : true;

      return matchesStore && matchesDate && matchesStatus;
    });
  }, [receipts, filters]);

  const handleDeleteReceipt = useCallback(async (receiptId, event) => {
    event.stopPropagation();
    setStatus({ type: "loading", message: "Deleting receipt..." });
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${config.API_BASE_URL}/delete_receipt/${receiptId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchReceiptsAndItems(); // Refresh the receipts after deletion
      setStatus({ type: "success", message: "Receipt deleted successfully." });
    } catch (error) {
      console.error("Error deleting receipt:", error);
      setStatus({ type: "error", message: "Failed to delete receipt." });
    }
  }, [fetchReceiptsAndItems]);

  const columns = useMemo(
    () => [
      {
        Header: "Merchant Name",
        accessor: "merchant_name",
      },
      {
        Header: "Date",
        accessor: "transaction_date",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: "Status",
        accessor: "validation_status",
        Cell: ({ cell: { value } }) =>
          value ? (
            <Flex justify="center" align="center">
              <CheckIcon color="green.500" mr={2} />
              <Text>Valid</Text>
            </Flex>
          ) : (
            <Flex justify="center" align="center">
              <CloseIcon color="red.500" mr={2} />
              <Text>Not Valid</Text>
            </Flex>
          ),
      },
      {
        Header: "Total",
        accessor: "total",
        isNumeric: true,
      },
      {
        Header: "Date Uploaded",
        accessor: "timestamp_created",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <Flex justify="center" align="center">
            <Button size="sm" onClick={() => onViewReceipt(row.original)}>View</Button>
            <Button size="sm" colorScheme="red" ml={2} onClick={(e) => handleDeleteReceipt(row.original.id, e)}>Delete</Button>
          </Flex>
        ),
      },
    ],
    [onViewReceipt, handleDeleteReceipt] // Added onViewReceipt and handleDeleteReceipt as dependencies
  );

  const data = useMemo(() => filteredReceipts, [filteredReceipts]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <Box overflowX="auto">
      {status.type && (
        <StatusMessage
          status={status.type}
          message={status.message}
          onClose={() => setStatus({ type: null, message: "" })}
        />
      )}
      <Table {...getTableProps()} variant="simple" colorScheme="teal">
        <TableCaption>Receipts Data</TableCaption>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())} textAlign="center">
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} onClick={() => onViewReceipt(row.original)} cursor="pointer">
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()} textAlign="center">
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ReceiptsTable;

import React, { useRef, useState, useCallback } from "react";
import {
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdUpload } from "react-icons/md";
import Dropzone from "./Dropzone";
import Card from "components/card/Card.js";
import axios from "axios";
import config from "config";
import StatusMessage from "components/StatusMessage";

export default function Upload(props) {
  const { used, total, ...rest } = props;
  const brandColor = useColorModeValue("brand.500", "white");
  const inputRef = useRef(null);

  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");

  const handleCloseStatusMessage = useCallback(() => {
    setStatus(null);
    setMessage("");
  }, []);

  const handleFileUpload = async (files) => {
    if (files.length === 0) {
      setStatus("error");
      setMessage("No file selected. Please select a file to upload.");
      return;
    }

    setStatus("loading");
    setMessage("Uploading...");

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("image", files[0]);

    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/upload_receipt`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setStatus("success");
        setMessage("Your receipt has been successfully uploaded.");
      } else {
        setStatus("error");
        setMessage(response.data.error || "Upload failed.");
      }
    } catch (error) {
      setStatus("error");
      setMessage(error.message);
    }
  };

  const handleInputChange = (event) => {
    const files = event.target.files;
    handleFileUpload(files);
  };

  const handleManualUpload = () => {
    inputRef.current.click();
  };

  return (
    <Card {...rest} mb="20px" p="20px" w="100%" h="100%" position="relative">
      <Dropzone
        w="100%"
        h="100%"
        onClick={handleManualUpload}
        onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
        content={
          <Flex align="center" h="100%">
            <Icon as={MdUpload} w="60px" h="60px" color={brandColor} mr="20px" />
            <Box>
              <Text fontSize="xl" fontWeight="700" color={brandColor}>
                Upload Files
              </Text>
              <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                PNG, JPG, GIF, and PDF files are allowed
              </Text>
            </Box>
          </Flex>
        }
      />
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleInputChange}
      />
      {status && (
        <StatusMessage
          status={status}
          message={message}
          onClose={handleCloseStatusMessage}
        />
      )}
    </Card>
  );
}
import React from "react";
import { Flex, Input, useColorModeValue } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";

function Dropzone(props) {
  const { content, onDrop, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true, // Prevent default click behavior to stop opening new window
    noKeyboard: true, // Prevent file selection with keyboard
    multiple: false, // Allow single file upload only
  });

  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border="1px dashed"
      borderColor={borderColor}
      borderRadius="16px"
      w="100%"
      h="max-content"
      minH="100%"
      cursor="pointer"
      {...getRootProps({ className: "dropzone" })}
      {...rest}
    >
      <Input {...getInputProps()} />
      {content}
    </Flex>
  );
}

export default Dropzone;

import React, { useState, useMemo } from "react";
import {
  Box,
  Input,
  Select,
  Button,
  HStack,
  VStack,
  List,
  ListItem,
  useBreakpointValue,
} from "@chakra-ui/react";

const FilterPanel = ({ applyFilters, receipts }) => {
  const [store, setStore] = useState("");
  const [status, setStatus] = useState("");
  const [dateFilterType, setDateFilterType] = useState("last30days"); // Default to last 30 days
  const [customDateType, setCustomDateType] = useState("specific"); // Type of custom date filtering
  const [specificDate, setSpecificDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [relativeDate, setRelativeDate] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true);

  const handleApplyFilters = () => {
    applyFilters({
      store,
      status,
      dateFilterType,
      customDateType,
      specificDate,
      startDate,
      endDate,
      relativeDate,
    });
  };

  const handleResetFilters = () => {
    setStore("");
    setStatus("");
    setDateFilterType("last30days"); // Reset to last 30 days
    setCustomDateType("specific"); // Reset custom date filter type
    setSpecificDate("");
    setStartDate("");
    setEndDate("");
    setRelativeDate("");
    setShowSuggestions(false);
    applyFilters({
      store: "",
      status: "",
      dateFilterType: "last30days",
      customDateType: "specific",
      specificDate: "",
      startDate: "",
      endDate: "",
      relativeDate: "",
    });
  };

  const storeSuggestions = useMemo(() => {
    const lowerCaseStore = store.toLowerCase();
    return receipts
      .map((receipt) => receipt.merchant_name)
      .filter(
        (name, index, self) =>
          name && name.toLowerCase().includes(lowerCaseStore) && self.indexOf(name) === index
      );
  }, [store, receipts]);

  const inputSize = useBreakpointValue({ base: "md", md: "sm" });

  const handleDateFilterChange = (e) => {
    setDateFilterType(e.target.value);
    if (e.target.value !== "custom") {
      // Reset custom dates if a preset is selected
      setCustomDateType("specific");
      setSpecificDate("");
      setStartDate("");
      setEndDate("");
      setRelativeDate("");
    }
  };

  return (
    <Box mb="20px" p="10px" borderWidth="1px" borderRadius="lg">
      <VStack spacing="10px" align="stretch">
        {/* First Line: Store, Status, and Time Period */}
        <HStack spacing="10px" width="100%">
          <Box flex="1">
            <Input
              placeholder="Store"
              value={store}
              onChange={(e) => {
                setStore(e.target.value);
                setShowSuggestions(true); // Show suggestions when typing
              }}
              size={inputSize}
            />
            {store && showSuggestions && (
              <List mt="5px" borderWidth="1px" borderRadius="md" maxHeight="100px" overflowY="auto">
                {storeSuggestions.map((suggestion, index) => (
                  <ListItem
                    key={index}
                    p="5px"
                    _hover={{ backgroundColor: "gray.100" }}
                    cursor="pointer"
                    onClick={() => {
                      setStore(suggestion);
                      setShowSuggestions(false); // Hide suggestions after selection
                    }}
                  >
                    {suggestion}
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <Box flex="1">
            <Select
              placeholder="Select status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              size={inputSize}
            >
              <option value="true">Valid</option>
              <option value="false">Not Valid</option>
            </Select>
          </Box>
          <Box flex="1">
            <Select
              placeholder="Time Period"
              value={dateFilterType}
              onChange={handleDateFilterChange}
              size={inputSize}
            >
              <option value="last7days">Last 7 Days</option>
              <option value="last30days">Last 30 Days</option>
              <option value="last90days">Last 90 Days</option>
              <option value="custom">Custom Date</option>
            </Select>
          </Box>
        </HStack>

        {/* Second Line: Custom Date Options */}
        {dateFilterType === "custom" && (
          <HStack spacing="10px" width="100%">
            <Select
              placeholder="Custom Date Type"
              value={customDateType}
              onChange={(e) => setCustomDateType(e.target.value)}
              size={inputSize}
              flex="1"
            >
              <option value="specific">Specific Date</option>
              <option value="before">Before Date</option>
              <option value="after">After Date</option>
              <option value="between">Between Dates</option>
            </Select>
            {customDateType === "between" ? (
              <>
                <Input
                  type="date"
                  placeholder="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  size={inputSize}
                  flex="1"
                />
                <Input
                  type="date"
                  placeholder="End Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  size={inputSize}
                  flex="1"
                />
              </>
            ) : (
              <Input
                type="date"
                placeholder={customDateType === "before" ? "Before Date" : customDateType === "after" ? "After Date" : "Select Date"}
                value={customDateType === "specific" ? specificDate : relativeDate}
                onChange={(e) => customDateType === "specific" ? setSpecificDate(e.target.value) : setRelativeDate(e.target.value)}
                size={inputSize}
                flex="1"
              />
            )}
          </HStack>
        )}

        {/* Apply and Reset Buttons */}
        <HStack spacing="10%" width="100%" justifyContent="center">
          <Button width="30%" onClick={handleApplyFilters} colorScheme="blue" size={inputSize} mx={2}>
            Apply Filters
          </Button>
          <Button width="30%" onClick={handleResetFilters} colorScheme="red" size={inputSize} mx={2}>
            Reset Filters
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default FilterPanel;

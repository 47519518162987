import React, { useState, useEffect } from 'react';
import { Flex, Table, Thead, Tbody, Tr, Th, Td, Select, Text, useColorModeValue } from '@chakra-ui/react';
import Card from "components/card/Card";

const TopSpendings = ({ receipts, items, columns, sortBy, timeframe }) => {
  const [aggregatedData, setAggregatedData] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState(timeframe);

  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  useEffect(() => {
    const getCurrentWeekRange = () => {
      const now = new Date();
      const start = new Date(now);
      start.setDate(now.getDate() - 7);
      const end = now;
      return { start, end };
    };

    const getCurrentMonthRange = () => {
      const now = new Date();
      const start = new Date(now.getFullYear(), now.getMonth(), 1);
      const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return { start, end };
    };

    const getCurrentYearRange = () => {
      const now = new Date();
      const start = new Date(now.getFullYear(), 0, 1);
      const end = new Date(now.getFullYear(), 11, 31);
      return { start, end };
    };

    const aggregateData = () => {
      const now = new Date();
      let startDate = new Date(0); // Default to epoch
      let endDate = now; // Default to now

      switch (selectedTimeframe) {
        case 'Week':
          startDate = getCurrentWeekRange().start;
          break;
        case 'Month':
          startDate = getCurrentMonthRange().start;
          break;
        case 'Year':
          startDate = getCurrentYearRange().start;
          break;
        default:
          break;
      }

      // Filter receipts by date range
      const filteredReceipts = receipts.filter(receipt => {
        const receiptDate = new Date(receipt.transaction_date);
        return receiptDate >= startDate && receiptDate <= endDate;
      });
      const filteredReceiptIds = filteredReceipts.map(receipt => receipt.id);

      // Filter items linked to filtered receipts
      const filteredItems = items.filter(item => filteredReceiptIds.includes(item.receipt_id));

      // Determine which data set to use
      const dataToUse = columns.some(col => col.key === "category") ? filteredItems : filteredReceipts;

      // Aggregate data by category or store
      const aggregatedMap = new Map();

      dataToUse.forEach(item => {
        const key = columns.some(col => col.key === "category") ? item.category : item.merchant_name;
        const amount = parseFloat(item[sortBy]) || 0;

        if (aggregatedMap.has(key)) {
          aggregatedMap.set(key, aggregatedMap.get(key) + amount);
        } else {
          aggregatedMap.set(key, amount);
        }
      });

      // Convert map to array and sort it
      const aggregatedArray = Array.from(aggregatedMap, ([key, total]) => ({ key, total }));
      aggregatedArray.sort((a, b) => b.total - a.total);

      // Limit to top 5
      setAggregatedData(aggregatedArray.slice(0, 5));
    };

    aggregateData();
  }, [receipts, items, selectedTimeframe, columns, sortBy]);

  const handleTimeframeChange = (event) => {
    setSelectedTimeframe(event.target.value);
  };

  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          {columns.some(col => col.key === "category") ? "Categories" : "Stores"}
        </Text>
        <Select value={selectedTimeframe} onChange={handleTimeframeChange} width="45%" height="100%">
          <option value="Week">Last Week</option>
          <option value="Month">Last Month</option>
          <option value="Year">Last Year</option>
        </Select>
      </Flex>
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            {columns.map((col, index) => (
              <Th key={index} borderColor={borderColor} pe="10px">
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  {col.label}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {aggregatedData.length === 0 ? (
            <Tr>
              <Td colSpan={columns.length} textAlign="center" borderColor={borderColor}>
                No data available
              </Td>
            </Tr>
          ) : (
            aggregatedData.map((item, index) => (
              <Tr key={index}>
                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {item.key}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  maxH="30px !important"
                  py="8px"
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    ${item.total.toFixed(2)}
                  </Text>
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Card>
  );
};

export default TopSpendings;

import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Flex,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import TotalSpent from "views/user/default/components/TotalSpent";
import DailyTraffic from "views/user/default/components/DailyTraffic";
import PieCard from "views/user/default/components/PieCard";
import axios from "axios";
import config from "config";
import ReceiptsModal from "views/user/default/components/ReceiptsModal";
import ItemsModal from "views/user/default/components/ItemsModal";
import Upload from "views/user/default/components/Upload";
import LatestReceiptsTable from "views/user/default/components/LatestReceiptsTable";
import TopSpendings from "views/user/default/components/TopSpendings";

export default function UserReports() {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [totalSpendings, setTotalSpendings] = useState(0);
  const [totalSpendingsThisMonth, setTotalSpendingsThisMonth] = useState(0);
  const [receipts, setReceipts] = useState([]);
  const [items, setItems] = useState([]);
  const [isReceiptsModalOpen, setReceiptsModalOpen] = useState(false);
  const [isItemsModalOpen, setItemsModalOpen] = useState(false);
  const [selectedReceiptId, setSelectedReceiptId] = useState(null);
  const [lastMonthSpendings, setLastMonthSpendings] = useState(0);

  // Fetch the receipts and items from the API
  const fetchReceiptsAndItems = async () => {
    try {
      const token = localStorage.getItem("token");

      const [receiptsResponse, itemsResponse] = await Promise.all([
        axios.get(`${config.API_BASE_URL}/get_all_receipts`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.API_BASE_URL}/get_all_items`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const receipts = receiptsResponse.data.receipts;
      const items = itemsResponse.data.items;

      setReceipts(receipts);
      setItems(items);

      // Store receipts and items in localStorage
      localStorage.setItem("receipts", JSON.stringify(receipts));
      localStorage.setItem("items", JSON.stringify(items));
    } catch (error) {
      console.error("Error fetching receipts and items:", error);
    }
  };

  // Load receipts and items from localStorage or API on mount
  useEffect(() => {
    const storedReceipts = JSON.parse(localStorage.getItem("receipts"));
    const storedItems = JSON.parse(localStorage.getItem("items"));

    if (storedReceipts && storedItems) {
      setReceipts(storedReceipts);
      setItems(storedItems);
    } else {
      fetchReceiptsAndItems();
    }
  }, []);

  // Recalculate spending metrics when receipts change
  useEffect(() => {
    if (receipts.length > 0) {
      const total = receipts.reduce((sum, receipt) => sum + parseFloat(receipt.total), 0);
      setTotalSpendings(total.toFixed(2));

      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
      const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

      const thisMonthReceipts = receipts.filter((receipt) => {
        const receiptDate = new Date(receipt.transaction_date);
        return receiptDate.getMonth() === currentMonth && receiptDate.getFullYear() === currentYear;
      });

      const lastMonthReceipts = receipts.filter((receipt) => {
        const receiptDate = new Date(receipt.transaction_date);
        return receiptDate.getMonth() === lastMonth && receiptDate.getFullYear() === lastMonthYear;
      });

      const thisMonthTotal = thisMonthReceipts.reduce(
        (sum, receipt) => sum + parseFloat(receipt.total), 0
      );
      setTotalSpendingsThisMonth(thisMonthTotal.toFixed(2));

      const lastMonthTotal = lastMonthReceipts.reduce(
        (sum, receipt) => sum + parseFloat(receipt.total), 0
      );
      setLastMonthSpendings(lastMonthTotal.toFixed(2));
    }
  }, [receipts]);

  const handleOpenReceiptsModal = () => {
    setSelectedReceiptId(null);
    setReceiptsModalOpen(true);
  };

  const handleCloseReceiptsModal = () => {
    setReceiptsModalOpen(false);
  };

  const handleOpenItemsModal = () => {
    setItemsModalOpen(true);
  };

  const handleCloseItemsModal = () => {
    setItemsModalOpen(false);
  };

  const handleItemClick = (receiptId) => {
    setSelectedReceiptId(receiptId);
    setReceiptsModalOpen(true);
    setItemsModalOpen(false);
  };

  const spendingDifference = (totalSpendingsThisMonth - lastMonthSpendings).toFixed(2);
  const growthPercentage = lastMonthSpendings !== 0
    ? ((spendingDifference / lastMonthSpendings) * 100).toFixed(2)
    : "0.00";
  const isDecrease = spendingDifference < 0;
  const growthText = isDecrease ? `${Math.abs(growthPercentage)}%` : `${growthPercentage}%`;
  const growthColor = isDecrease ? "green.500" : "red.500";

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex justify="space-between" mb="20px">
        <Button onClick={fetchReceiptsAndItems} colorScheme="blue" variant="solid">
          Refresh
        </Button>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }} gap="20px" mb="20px">
        <Box gridColumn={{ base: "span 1", md: "span 2" }} w="100%" h="100%" display="flex">
          <Upload />
        </Box>
        <Box onClick={handleOpenReceiptsModal} cursor="pointer" w="100%" h="100%" display="flex">
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<MdBarChart color={brandColor} />} />
            }
            name="Number of Receipts"
            value={receipts.length}
          />
        </Box>
        <Box w="100%" h="100%" display="flex">
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<MdAttachMoney color={brandColor} />} />
            }
            name="Spent This Year"
            value={`$${totalSpendings}`}
          />
        </Box>
        <Box w="100%" h="100%" display="flex">
          <MiniStatistics growth={growthText} growthColor={growthColor} name="Spent This Month" value={`$${totalSpendingsThisMonth}`} />
        </Box>
        <Box onClick={handleOpenItemsModal} cursor="pointer" w="100%" h="100%" display="flex">
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<MdFileCopy color={brandColor} />} />
            }
            name="Total Items Purchased"
            value={items.length}
          />
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <TotalSpent receipts={receipts}/>
        <DailyTraffic receipts={receipts} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <PieCard items={items} receipts={receipts}/>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 2, xl: 4 }} gap="20px" mb="20px">
        <Box gridColumn={{ base: "span 2", xl: "span 2" }} w="100%" h="100%">
          <LatestReceiptsTable receipts={receipts} />
        </Box>
        <Box gridColumn={{ base: "span 1", xl: "span 1" }} w="100%" h="100%">
          <TopSpendings
            receipts={receipts}
            items={items}
            columns={[
              { label: "Store Name", key: "merchant_name" },
              { label: "Total", key: "total" },
            ]}
            sortBy="total"
            timeframe="Month"
          />
        </Box>
        <Box gridColumn={{ base: "span 1", xl: "span 1" }} w="100%" h="100%">
          <TopSpendings
            receipts={receipts}
            items={items}
            columns={[
              { label: "Category Name", key: "category" },
              { label: "Total", key: "total_price" },
            ]}
            sortBy="total_price"
            timeframe="Month"
          />
        </Box>
      </SimpleGrid>
      <ReceiptsModal isOpen={isReceiptsModalOpen} onClose={handleCloseReceiptsModal} receipts={receipts} items={items} selectedReceiptId={selectedReceiptId} />
      <ItemsModal isOpen={isItemsModalOpen} onClose={handleCloseItemsModal} items={items} onItemClick={handleItemClick} />
    </Box>
  );
}

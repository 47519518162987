import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ColumnChart = ({ chartData, chartOptions }) => {
  const [options, setOptions] = useState(chartOptions);
  const [data, setData] = useState(chartData);

  useEffect(() => {
    setOptions(chartOptions);
    setData(chartData);
  }, [chartData, chartOptions]);

  return (
    <Chart
      options={options}
      series={data}
      type='bar'
      width='100%'
      height='100%'
    />
  );
};

export default ColumnChart;

import React, { useEffect } from "react";
import { Box, Text, Spinner, IconButton, useColorModeValue } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const StatusMessage = ({ status, message, onClose }) => {
  const bg = useColorModeValue("gray.100", "navy.700");

  useEffect(() => {
    if (status !== "loading") {
      const timer = setTimeout(onClose, 5000);
      return () => clearTimeout(timer);
    }
  }, [status, onClose]);

  return (
    <Box
      position="fixed"
      top="10px"
      right="10px"
      bg={bg}
      p="20px"
      borderRadius="8px"
      boxShadow="lg"
      display="flex"
      alignItems="center"
      zIndex="9999" // Ensures the component is above all other elements
    >
      {status === "loading" ? (
        <>
          <Spinner size="lg" mr="10px" />
          <Text color="gray.500" mr="10px">
            Loading...
          </Text>
        </>
      ) : (
        <Text color={status === "success" ? "green.500" : "red.500"} mr="10px">
          {message}
        </Text>
      )}
      <IconButton
        icon={<CloseIcon />}
        size="md"
        onClick={onClose}
        aria-label="Close"
      />
    </Box>
  );
};

export default StatusMessage;

import React, { useRef, useState, useCallback } from "react";
import { Button } from "@chakra-ui/react";
import { MdFileUpload } from "react-icons/md";
import axios from "axios";
import config from "config";
import StatusMessage from "components/StatusMessage";

const UploadButton = () => {
  //const brandColor = useColorModeValue("brand.500", "white");
  const inputRef = useRef(null);

  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");

  const handleCloseStatusMessage = useCallback(() => {
    setStatus(null);
    setMessage("");
  }, []);

  const handleFileUpload = async (files) => {
    if (files.length === 0) {
      setStatus("error");
      setMessage("No file selected. Please select a file to upload.");
      return;
    }

    setStatus("loading");
    setMessage("Uploading...");

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("image", files[0]);

    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/upload_receipt`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setStatus("success");
        setMessage("Your receipt has been successfully uploaded.");
      } else {
        setStatus("error");
        setMessage(response.data.error || "Upload failed.");
      }
    } catch (error) {
      setStatus("error");
      setMessage(error.message);
    }
  };

  const handleInputChange = (event) => {
    const files = event.target.files;
    handleFileUpload(files);
  };

  const handleManualUpload = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Button leftIcon={<MdFileUpload />} colorScheme="green" variant="solid" onClick={handleManualUpload}>
        Upload
      </Button>
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleInputChange}
      />
      {status && (
        <StatusMessage
          status={status}
          message={message}
          onClose={handleCloseStatusMessage}
        />
      )}
    </>
  );
};

export default UploadButton;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  Select,
  Spinner,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import { IoCheckmarkCircle, IoAlertCircle, IoRemoveCircle } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import { lineChartOptionsTotalSpent } from "variables/charts";

export default function TotalSpent({ receipts, ...rest }) {
  const [percentageChange, setPercentageChange] = useState(0);
  const [statusMessage, setStatusMessage] = useState("On track");
  const [chartData, setChartData] = useState([{ actual: Array(6).fill(0) }]);
  const [timeFrame, setTimeFrame] = useState('month');
  const [period, setPeriod] = useState(6);
  const [loading, setLoading] = useState(true);
  const [totalSpent, setTotalSpent] = useState(0);

  useEffect(() => {
    setLoading(true);
    const now = new Date();
    let lastPeriods;
    let periodReceipts;

    if (timeFrame === 'week') {
      lastPeriods = Array.from({ length: period }, (_, i) => {
        const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - (i * 7));
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        return startOfWeek;
      });

      periodReceipts = date => receipts.filter(receipt => {
        const receiptDate = new Date(receipt.transaction_date);
        const weekEnd = new Date(date);
        weekEnd.setDate(weekEnd.getDate() + 7);
        return receiptDate >= date && receiptDate < weekEnd;
      });
    } else if (timeFrame === 'month') {
      lastPeriods = Array.from({ length: period }, (_, i) => new Date(now.getFullYear(), now.getMonth() - i, 1));
      periodReceipts = date => receipts.filter(receipt => {
        const receiptDate = new Date(receipt.transaction_date);
        return receiptDate.getMonth() === date.getMonth() && receiptDate.getFullYear() === date.getFullYear();
      });
    }

    const actualSpending = lastPeriods.map(date => periodReceipts(date).reduce((sum, receipt) => sum + parseFloat(receipt.total), 0)).reverse();

    setChartData([{ actual: actualSpending }]);

    const thisPeriodSpending = actualSpending[actualSpending.length - 1];
    const lastPeriodSpending = actualSpending[actualSpending.length - 2];
    const changePercentage = lastPeriodSpending !== 0 
      ? ((thisPeriodSpending - lastPeriodSpending) / lastPeriodSpending) * 100
      : 0;

    setTotalSpent(thisPeriodSpending.toFixed(2));
    setPercentageChange(changePercentage.toFixed(2));
    
    if (changePercentage > 0) {
      setStatusMessage("Spending increased");
    } else if (changePercentage < 0) {
      setStatusMessage("Spending decreased");
    } else {
      setStatusMessage("No change");
    }

    setLoading(false);
  }, [receipts, timeFrame, period]);

  const updatedLineChartData = [
    {
      name: "Spent",
      data: chartData[0].actual,
    },
  ];

  const textColor = useColorModeValue("secondaryGray.900", "white");
  //const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb='0px'
      {...rest}>
      <Flex justify='space-between' ps='0px' pe='20px' pt='5px' w='100%'>
        <Flex align='center' w='100%'>
          <Select
            fontSize='sm'
            variant='subtle'
            value={timeFrame}
            width='unset'
            fontWeight='700'
            onChange={(e) => setTimeFrame(e.target.value)}>
            <option value='week'>Weeks</option>
            <option value='month'>Months</option>
          </Select>
          <Select
            fontSize='sm'
            variant='subtle'
            value={period}
            width='unset'
            fontWeight='700'
            onChange={(e) => setPeriod(parseInt(e.target.value, 10))}>
            {timeFrame === 'week' && Array.from({ length: 7 }, (_, i) => i + 2).map(num => (
              <option key={num} value={num}>{num} Weeks</option>
            ))}
            {timeFrame === 'month' && Array.from({ length: 9 }, (_, i) => i + 2).map(num => (
              <option key={num} value={num}>{num} Months</option>
            ))}
          </Select>
          <Button
            ms='auto'
            align='center'
            justifyContent='center'
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w='37px'
            h='37px'
            lineHeight='100%'
            borderRadius='10px'
            {...rest}>
            <Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
          </Button>
        </Flex>
      </Flex>
      <Flex w='100%' flexDirection={{ base: "column", lg: "row" }}>
        <Flex flexDirection='column' me='20px' mt='28px'>
          <Text
            color={textColor}
            fontSize='34px'
            textAlign='start'
            fontWeight='700'
            lineHeight='100%'>
            ${totalSpent}
          </Text>
          <Flex align='center' mb='20px'>
            <Text
              color='secondaryGray.600'
              fontSize='sm'
              fontWeight='500'
              mt='4px'
              me='12px'>
              Spent
            </Text>
            <Flex align='center'>
              {percentageChange >= 0 ? (
                <Icon as={RiArrowUpSFill} color='green.500' me='2px' mt='2px' />
              ) : (
                <Icon as={RiArrowDownSFill} color='red.500' me='2px' mt='2px' />
              )}
              <Text color={percentageChange >= 0 ? 'green.500' : 'red.500'} fontSize='sm' fontWeight='700'>
                {percentageChange}%
              </Text>
            </Flex>
          </Flex>

          <Flex align='center'>
            <Icon as={statusMessage === "Spending increased" ? IoCheckmarkCircle : statusMessage === "Spending decreased" ? IoAlertCircle : IoRemoveCircle} color={statusMessage === "Spending increased" ? 'green.500' : statusMessage === "Spending decreased" ? 'red.500' : 'gray.500'} me='4px' />
            <Text color={statusMessage === "Spending increased" ? 'green.500' : statusMessage === "Spending decreased" ? 'red.500' : 'gray.500'} fontSize='md' fontWeight='700'>
              {statusMessage}
            </Text>
          </Flex>
        </Flex>
        <Box minH='260px' minW='75%' mt='auto'>
          {loading ? (
            <Spinner size="xl" />
          ) : (
            <LineChart
              chartData={updatedLineChartData}
              chartOptions={lineChartOptionsTotalSpent(timeFrame, period)}
            />
          )}
        </Box>
      </Flex>
    </Card>
  );
}

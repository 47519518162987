import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormErrorMessage,
  Box,
  Flex,
  Select
} from "@chakra-ui/react";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import config from "config";
import StatusMessage from "components/StatusMessage";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0]; // Converts to 'YYYY-MM-DD'
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[1].split('Z')[0]; // Converts to 'HH:MM:SS'
};

const EditReceiptModal = ({
  isOpen,
  onClose,
  receipt,
  allItems,
  fetchReceiptsAndItems,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedReceipt, setEditedReceipt] = useState({ ...receipt });
  const [items, setItems] = useState([...allItems]);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState(""); // State for status
  const [message, setMessage] = useState(""); // State for message

  useEffect(() => {
    if (isOpen) {
      setIsEditMode(false);
      const receiptWithDefaults = {
        ...receipt,
        merchant_address: receipt?.merchant_address || { city: "", state: "" },
      };
  
      setEditedReceipt({
        ...receiptWithDefaults,
        total: parseFloat(receiptWithDefaults.total || 0).toFixed(2),
        subtotal: parseFloat(receiptWithDefaults.subtotal || 0).toFixed(2),
        total_tax: parseFloat(receiptWithDefaults.total_tax || 0).toFixed(2),
      });
  
      setItems(allItems.map(item => ({
        ...item,
        total_price: parseFloat(item.total_price).toFixed(2),
      })));
      setItemsToDelete([]);
      setErrors({});
    }
  }, [isOpen, receipt, allItems]);
  
  
  

  const validateInput = (name, value) => {
    let error = "";
    if (["total", "subtotal", "total_tax", "total_price"].includes(name) && !/^\d+(\.\d{1,2})?$/.test(value)) {
      error = "Must be a non-negative number with up to 2 decimal places";
    }
    return error;
  };

  const handleReceiptChange = (e) => {
    const { name, value } = e.target;
    const error = validateInput(name, value);
    setErrors((prevState) => ({ ...prevState, [name]: error }));
    setEditedReceipt((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const error = validateInput(name, value);
    setErrors((prevState) => ({ ...prevState, [`item_${index}_${name}`]: error }));
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index] = { ...newItems[index], [name]: value };
      return newItems;
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value)) {
      setEditedReceipt((prevState) => ({ ...prevState, [name]: parseFloat(value).toFixed(2) }));
    }
  };

  const handleItemBlur = (index, e) => {
    const { name, value } = e.target;
    if (!isNaN(value)) {
      setItems((prevItems) => {
        const newItems = [...prevItems];
        newItems[index] = { ...newItems[index], [name]: parseFloat(value).toFixed(2) };
        return newItems;
      });
    }
  };

  const handleAddItem = () => {
    const newItem = {
      id: uuidv4(),
      receipt_id: editedReceipt.id,
      description: "",
      quantity: 0,
      total_price: "0.00",
      category: "" // Default category
    };
    setItems((prevItems) => [...prevItems, newItem]);
  };

  const handleDeleteItem = (index) => {
    const itemToDelete = items[index];
    if (itemToDelete.id) {
      setItemsToDelete((prev) => [...prev, itemToDelete.id]);
    } else {
      setItems((prevItems) => prevItems.filter((_, i) => i !== index));
    }
  };

  const handleUndoDelete = (itemId) => {
    setItemsToDelete((prev) => prev.filter((id) => id !== itemId));
  };

  const handleSave = async () => {
    setStatus("loading");
    setMessage("Saving changes...");
    if (window.confirm("Are you sure you want to save the changes?")) {
      const token = localStorage.getItem("token");
      try {
        const receiptToSave = {
          ...editedReceipt,
          total: parseFloat(editedReceipt.total),
          subtotal: parseFloat(editedReceipt.subtotal),
          total_tax: parseFloat(editedReceipt.total_tax)
        };

        if (JSON.stringify(receipt) !== JSON.stringify(receiptToSave)) {
          await axios.patch(`${config.API_BASE_URL}/update_receipt/${receiptToSave.id}`, receiptToSave, {
            headers: { Authorization: `Bearer ${token}` },
          });
        }

        for (const item of items) {
          const itemToSave = { ...item, total_price: parseFloat(item.total_price) };

          if (item.id && allItems.some(ai => ai.id === item.id)) {
            await axios.patch(`${config.API_BASE_URL}/update_item/${itemToSave.id}`, itemToSave, {
              headers: { Authorization: `Bearer ${token}` },
            });
          } else {
            await axios.post(`${config.API_BASE_URL}/add_item`, itemToSave, {
              headers: { Authorization: `Bearer ${token}` },
            });
          }
        }

        for (const itemId of itemsToDelete) {
          await axios.delete(`${config.API_BASE_URL}/delete_item/${itemId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        }

        fetchReceiptsAndItems();
        onClose();
        setStatus("success");
        setMessage("Changes saved successfully.");
      } catch (error) {
        console.error("Error saving receipt:", error);
        setStatus("error");
        setMessage("Failed to save changes.");
      }
    }
  };

  const handleMerchantAddressChange = (e) => {
    const { name, value } = e.target;
    setEditedReceipt((prevState) => ({
      ...prevState,
      merchant_address: {
        ...prevState.merchant_address,
        [name]: value,
      },
    }));
  };


  return (
    <>
      {status && <StatusMessage status={status} message={message} onClose={() => setStatus("")} />}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "90vw", md: "60vw" }} p={4}>
          <ModalHeader>
            {isEditMode ? "Edit Receipt" : "Receipt Details"}
            {!isEditMode && (
              <Button colorScheme="blue" onClick={() => setIsEditMode(true)} ml={4}>
                Edit
              </Button>
            )}
          </ModalHeader>
          <ModalCloseButton />
  
          <ModalBody>
            {/* Flex layout for the receipt details */}
            <Flex direction={{ base: "column", md: "row" }} gap={4} wrap="wrap">
              <FormControl isInvalid={!!errors.merchant_name}>
                <Input
                  name="merchant_name"
                  placeholder="Merchant Name"
                  value={editedReceipt.merchant_name}
                  onChange={handleReceiptChange}
                  isReadOnly={!isEditMode}
                />
                <FormErrorMessage>{errors.merchant_name}</FormErrorMessage>
              </FormControl>
  
              <FormControl isInvalid={!!errors.merchant_address_city} mb="10px">
                <Input
                  name="city"
                  placeholder="Merchant City"
                  value={editedReceipt.merchant_address?.city || ""}
                  onChange={handleMerchantAddressChange}
                  isReadOnly={!isEditMode}
                />
                <FormErrorMessage>{errors.merchant_address_city}</FormErrorMessage>
              </FormControl>
  
              <FormControl isInvalid={!!errors.merchant_address_state} mb="10px">
                <Input
                  name="state"
                  placeholder="Merchant State"
                  value={editedReceipt.merchant_address?.state || ""}
                  onChange={handleMerchantAddressChange}
                  isReadOnly={!isEditMode}
                />
                <FormErrorMessage>{errors.merchant_address_state}</FormErrorMessage>
              </FormControl>
  
              <FormControl isInvalid={!!errors.transaction_date} mb="10px">
                <Input
                  name="transaction_date"
                  type="date"
                  value={formatDate(editedReceipt.transaction_date)}
                  onChange={handleReceiptChange}
                  isReadOnly={!isEditMode}
                />
                <FormErrorMessage>{errors.transaction_date}</FormErrorMessage>
              </FormControl>
  
              <FormControl isInvalid={!!errors.transaction_time} mb="10px">
                <Input
                  name="transaction_time"
                  type="time"
                  value={formatTime(editedReceipt.transaction_date)}
                  onChange={handleReceiptChange}
                  isReadOnly={!isEditMode}
                />
                <FormErrorMessage>{errors.transaction_time}</FormErrorMessage>
              </FormControl>
  
              <FormControl isInvalid={!!errors.total} mb="10px">
                <Input
                  name="total"
                  placeholder="Total"
                  value={editedReceipt.total}
                  onChange={handleReceiptChange}
                  onBlur={handleBlur}
                  isReadOnly={!isEditMode}
                />
                <FormErrorMessage>{errors.total}</FormErrorMessage>
              </FormControl>
  
              <FormControl isInvalid={!!errors.subtotal} mb="10px">
                <Input
                  name="subtotal"
                  placeholder="Subtotal"
                  value={editedReceipt.subtotal}
                  onChange={handleReceiptChange}
                  onBlur={handleBlur}
                  isReadOnly={!isEditMode}
                />
                <FormErrorMessage>{errors.subtotal}</FormErrorMessage>
              </FormControl>
  
              <FormControl isInvalid={!!errors.total_tax} mb="10px">
                <Input
                  name="total_tax"
                  placeholder="Total Tax"
                  value={editedReceipt.total_tax}
                  onChange={handleReceiptChange}
                  onBlur={handleBlur}
                  isReadOnly={!isEditMode}
                />
                <FormErrorMessage>{errors.total_tax}</FormErrorMessage>
              </FormControl>
            </Flex>
  
            {/* Add a header for the items section */}
            <Box mt={6} mb={4}>
              <strong>Items</strong>
            </Box>
  
            {/* Pair of Add and Save buttons at the top */}
            {isEditMode && (
              <Flex justify="space-between" mb={4}>
                <Button colorScheme="teal" onClick={handleAddItem}>Add Item</Button>
                <Box>
                  <Button colorScheme="blue" onClick={handleSave} ml={4}>Save</Button>
                </Box>
              </Flex>
            )}
  
            {/* Column headers */}
            <Flex display={{ base: "none", md: "flex" }} mb={2} fontWeight="bold">
              <Box flex={1}>Description</Box>
              <Box flex={1}>Quantity</Box>
              <Box flex={1}>Price</Box>
              <Box flex={1}>Category</Box>
              <Box flex={0.5}></Box>
            </Flex>
  
            {/* Item list layout */}
            <Box>
              {items.map((item, index) => (
                <Box
                  key={item.id}
                  p={4}
                  mb={4}
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  bg="gray.50"
                >
                  {/* Flex layout for items, stacked on mobile */}
                  <Flex direction={{ base: "column", md: "row" }} gap={4}>
                    <FormControl isInvalid={!!errors[`item_${index}_description`]}>
                      <Box display={{ base: "block", md: "none" }} fontWeight="bold" mb={1}>
                        Description
                      </Box>
                      <Input
                        name="description"
                        placeholder="Description"
                        value={item.description}
                        onChange={(e) => handleItemChange(index, e)}
                        isReadOnly={!isEditMode}
                      />
                      <FormErrorMessage>{errors[`item_${index}_description`]}</FormErrorMessage>
                    </FormControl>
  
                    <FormControl isInvalid={!!errors[`item_${index}_quantity`]}>
                      <Box display={{ base: "block", md: "none" }} fontWeight="bold" mb={1}>
                        Quantity
                      </Box>
                      <Input
                        name="quantity"
                        placeholder="Quantity"
                        value={item.quantity}
                        onChange={(e) => handleItemChange(index, e)}
                        isReadOnly={!isEditMode}
                      />
                      <FormErrorMessage>{errors[`item_${index}_quantity`]}</FormErrorMessage>
                    </FormControl>
  
                    <FormControl isInvalid={!!errors[`item_${index}_total_price`]}>
                      <Box display={{ base: "block", md: "none" }} fontWeight="bold" mb={1}>
                        Price
                      </Box>
                      <Input
                        name="total_price"
                        placeholder="Price"
                        value={item.total_price}
                        onChange={(e) => handleItemChange(index, e)}
                        onBlur={(e) => handleItemBlur(index, e)}
                        isReadOnly={!isEditMode}
                      />
                      <FormErrorMessage>{errors[`item_${index}_total_price`]}</FormErrorMessage>
                    </FormControl>
  
                    <FormControl>
                      <Box display={{ base: "block", md: "none" }} fontWeight="bold" mb={1}>
                        Category
                      </Box>
                      {!isEditMode ? (
                        <Box>{item.category}</Box>
                      ) : (
                        <Select
                          name="category"
                          value={item.category}
                          onChange={(e) => handleItemChange(index, e)}
                          isReadOnly={!isEditMode}
                          isDisabled={!isEditMode} // Ensure dropdown is disabled if not in edit mode
                        >
                          <option value="Fruits and Vegetables">Fruits and Vegetables</option>
                          <option value="Canned Goods">Canned Goods</option>
                          <option value="Dairy">Dairy</option>
                          <option value="Meat">Meat</option>
                          <option value="Fish and Seafood">Fish and Seafood</option>
                          <option value="Deli">Deli</option>
                          <option value="Condiments and Sauces">Condiments and Sauces</option>
                          <option value="Snacks">Snacks</option>
                          <option value="Breads and Bakery">Breads and Bakery</option>
                          <option value="Beverages">Beverages</option>
                          <option value="Frozen Foods">Frozen Foods</option>
                          <option value="Grains, Pasta and Sides">Grains, Pasta and Sides</option>
                          <option value="Baking">Baking</option>
                          <option value="Personal Care">Personal Care</option>
                          <option value="Household and Cleaning">Household and Cleaning</option>
                          <option value="Health">Health</option>
                          <option value="Baby">Baby</option>
                          <option value="Pets">Pets</option>
                          <option value="Alcohol">Alcohol</option>
                          <option value="Uncategorized">Uncategorized</option>
                        </Select>
                      )}
                    </FormControl>
  
                    {isEditMode && (
                      <Box mt={{ base: 4, md: 0 }}>
                        {itemsToDelete.includes(item.id) ? (
                          <Button onClick={() => handleUndoDelete(item.id)}>Undo</Button>
                        ) : (
                          <Button onClick={() => handleDeleteItem(index)}>Delete</Button>
                        )}
                      </Box>
                    )}
                  </Flex>
                </Box>
              ))}
            </Box>
  
            {/* Pair of Add and Save buttons at the bottom */}
            {isEditMode && (
              <Flex justify="space-between" mt={4}>
                <Button colorScheme="teal" onClick={handleAddItem}>Add Item</Button>
                <Box>
                  <Button colorScheme="blue" onClick={handleSave} ml={4}>Save</Button>
                </Box>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );  
}  

export default EditReceiptModal;
import {
    Flex,
    Table,
    Icon,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";
  import React, { useMemo } from "react";
  import { useTable } from "react-table";
  import Card from "components/card/Card";
  import { MdCheckCircle, MdCancel } from "react-icons/md";
  
  export default function LatestReceiptsTable({ receipts }) {
    // Define columns for the table
    const columnsData = useMemo(
      () => [
        {
          Header: "STORE NAME",
          accessor: "storeName",
        },
        {
          Header: "STATUS",
          accessor: "status",
        },
        {
          Header: "DATE",
          accessor: "transactionDate",
        },
        {
          Header: "TOTAL",
          accessor: "total",
        },
      ],
      []
    );
  
    // Sort receipts by date in descending order and select the latest 5
    const sortedReceipts = receipts
      .slice()
      .sort((a, b) => new Date(b.transaction_date) - new Date(a.transaction_date))
      .slice(0, 5);
  
    // Prepare table data
    const tableData = useMemo(
      () =>
        sortedReceipts.map((receipt) => ({
          storeName: receipt.merchant_name,
          status: receipt.validation_status ? "Valid" : "Not Valid",
          transactionDate: new Date(receipt.transaction_date).toLocaleDateString(),
          total: `$${parseFloat(receipt.total).toFixed(2)}`,
        })),
      [sortedReceipts]
    );
  
    const tableInstance = useTable({ columns: columnsData, data: tableData });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      tableInstance;
  
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  
    return (
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Latest Receipts
          </Text>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps()}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data;
                    if (cell.column.Header === "STATUS") {
                      data = (
                        <Flex align="center">
                          <Icon
                            w="24px"
                            h="24px"
                            me="5px"
                            color={cell.value === "Valid" ? "green.500" : "red.500"}
                            as={cell.value === "Valid" ? MdCheckCircle : MdCancel}
                          />
                          <Text color={textColor} fontSize="sm" fontWeight="700">
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        maxH="30px !important"
                        py="8px"
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Card>
    );
  }
  
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  FormControl,
  FormErrorMessage,
  Tooltip,
} from "@chakra-ui/react";
import axios from "axios";
import config from "config";
import StatusMessage from "components/StatusMessage";

const EditItemModal = ({
  isOpen,
  onClose,
  item,
  receipts,
  fetchItemsAndReceipts,
}) => {
  const [editedItem, setEditedItem] = useState({ ...item });
  const [status, setStatus] = useState(""); 
  const [message, setMessage] = useState(""); 
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isOpen) {
      // Format total_price to 2 decimal places when setting the editedItem state
      setEditedItem({
        ...item,
        total_price: parseFloat(item.total_price).toFixed(2),
      });
      setErrors({});
    }
  }, [isOpen, item]);

  const validateInput = (name, value) => {
    let error = "";
    if (["quantity", "total_price"].includes(name) && !/^\d+(\.\d{1,2})?$/.test(value)) {
      error = "Must be a non-negative number with up to 2 decimal places";
    }
    return error;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const error = validateInput(name, value);
    setErrors((prevState) => ({ ...prevState, [name]: error }));
    setEditedItem((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSave = async () => {
    setStatus("loading");
    setMessage("Saving changes...");
    if (window.confirm("Are you sure you want to save the changes?")) {
      const token = localStorage.getItem("token");
      try {
        const itemToSave = {
          ...editedItem,
          total_price: parseFloat(editedItem.total_price).toFixed(2),
        };

        await axios.patch(`${config.API_BASE_URL}/update_item/${itemToSave.id}`, itemToSave, {
          headers: { Authorization: `Bearer ${token}` },
        });

        fetchItemsAndReceipts();
        onClose();
        setStatus("success");
        setMessage("Changes saved successfully.");
      } catch (error) {
        console.error("Error saving item:", error);
        setStatus("error");
        setMessage("Failed to save changes.");
      }
    }
  };

  const receipt = receipts.find(r => r.id === editedItem.receipt_id);

  return (
    <>
      {status && <StatusMessage status={status} message={message} onClose={() => setStatus("")} />}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="60vw">
          <ModalHeader>Edit Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tooltip label="Item Description" aria-label="Description Tooltip">
              <FormControl isInvalid={!!errors.description} mb="10px">
                <Input
                  name="description"
                  placeholder="Description"
                  value={editedItem.description}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.description}</FormErrorMessage>
              </FormControl>
            </Tooltip>
            <Tooltip label="Quantity" aria-label="Quantity Tooltip">
              <FormControl isInvalid={!!errors.quantity} mb="10px">
                <Input
                  name="quantity"
                  placeholder="Quantity"
                  value={editedItem.quantity}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.quantity}</FormErrorMessage>
              </FormControl>
            </Tooltip>
            <Tooltip label="Total Price" aria-label="Total Price Tooltip">
              <FormControl isInvalid={!!errors.total_price} mb="10px">
                <Input
                  name="total_price"
                  placeholder="Total Price"
                  value={editedItem.total_price}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.total_price}</FormErrorMessage>
              </FormControl>
            </Tooltip>
            <Tooltip label="Category" aria-label="Category Tooltip">
              <FormControl mb="10px">
                <Select
                  name="category"
                  placeholder="Select category"
                  value={editedItem.category}
                  onChange={handleInputChange}
                >
                  <option value="Fruits and Vegetables">Fruits and Vegetables</option>
                  <option value="Canned Goods">Canned Goods</option>
                  <option value="Dairy">Dairy</option>
                  <option value="Meat">Meat</option>
                  <option value="Fish and Seafood">Fish and Seafood</option>
                  <option value="Deli">Deli</option>
                  <option value="Condiments and Sauces">Condiments and Sauces</option>
                  <option value="Snacks">Snacks</option>
                  <option value="Breads and Bakery">Breads and Bakery</option>
                  <option value="Beverages">Beverages</option>
                  <option value="Frozen Foods">Frozen Foods</option>
                  <option value="Grains, Pasta and Sides">Grains, Pasta and Sides</option>
                  <option value="Baking">Baking</option>
                  <option value="Personal Care">Personal Care</option>
                  <option value="Household and Cleaning">Household and Cleaning</option>
                  <option value="Health">Health</option>
                  <option value="Baby">Baby</option>
                  <option value="Pets">Pets</option>
                  <option value="Alcohol">Alcohol</option>
                  <option value="Uncategorized">Uncategorized</option>
                </Select>
              </FormControl>
            </Tooltip>
            <Tooltip label="Store (not editable)" aria-label="Store Tooltip">
              <FormControl mb="10px">
                <Input
                  name="store"
                  placeholder="Store"
                  value={receipt ? receipt.merchant_name : "Unknown"}
                  isReadOnly
                />
              </FormControl>
            </Tooltip>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSave} ml={4}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditItemModal;

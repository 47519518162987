import React, { useEffect, useState } from "react";
import { Box, Flex, Icon, Text, Spinner, Button, ButtonGroup } from "@chakra-ui/react";
import BarChart from "components/charts/BarChart";
import Card from "components/card/Card.js";
import { RiArrowUpSFill } from "react-icons/ri";

export default function DailyTraffic({ receipts, ...rest }) {
  const [barChartDataMonthly, setBarChartDataMonthly] = useState([]);
  const [barChartOptionsMonthly, setBarChartOptionsMonthly] = useState({});
  const [barChartDataYearly, setBarChartDataYearly] = useState([]);
  const [barChartOptionsYearly, setBarChartOptionsYearly] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState('monthly');

  const getCurrentMonthRange = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return { start, end };
  };

  const getCurrentYearRange = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const end = new Date(now.getFullYear(), 11, 31);
    return { start, end };
  };

  const calculateSpendingByStore = (receipts, fromDate, toDate) => {
    const storeData = {};

    receipts.forEach(receipt => {
      const receiptDate = new Date(receipt.transaction_date);
      if (receiptDate >= fromDate && receiptDate <= toDate) {
        const store = receipt.merchant_name || 'Unknown';
        if (!storeData[store]) {
          storeData[store] = 0;
        }
        storeData[store] += parseFloat(receipt.total);
      }
    });

    return Object.entries(storeData).map(([store, total]) => ({
      store,
      total
    }));
  };

  const setChartOptions = (categories) => ({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: "#4318FF",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgba(67, 24, 255, 1)",
            opacity: 0.28,
          },
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
  });

  useEffect(() => {
    if (receipts && receipts.length > 0) {
      const { start: monthStart, end: monthEnd } = getCurrentMonthRange();
      const { start: yearStart, end: yearEnd } = getCurrentYearRange();
  
      const monthlySpending = calculateSpendingByStore(receipts, monthStart, monthEnd);
      const yearlySpending = calculateSpendingByStore(receipts, yearStart, yearEnd);
  
      setBarChartDataMonthly([
        {
          name: "Monthly Spending",
          data: monthlySpending.map(store => store.total),
        },
      ]);
      setBarChartOptionsMonthly(setChartOptions(monthlySpending.map(store => store.store)));
  
      setBarChartDataYearly([
        {
          name: "Yearly Spending",
          data: yearlySpending.map(store => store.total),
        },
      ]);
      setBarChartOptionsYearly(setChartOptions(yearlySpending.map(store => store.store)));
    } else {
      // Reset the chart data and options when receipts are empty
      setBarChartDataMonthly([]);
      setBarChartOptionsMonthly({});
      setBarChartDataYearly([]);
      setBarChartOptionsYearly({});
    }
    setIsLoading(false);
  }, [receipts]);
  

  //const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card align='center' direction='column' w='100%' {...rest}>
      <Flex justify='space-between' align='start' px='10px' pt='5px'>
        <Flex flexDirection='column' align='start' me='20px'>
          <Flex w='100%'>
            <Text
              me='auto'
              color='secondaryGray.600'
              fontSize='sm'
              fontWeight='500'>
              Monthly & Yearly Spending
            </Text>
          </Flex>
        </Flex>
        <Flex align='center'>
          <Icon as={RiArrowUpSFill} color='green.500' />
          <Text color='green.500' fontSize='sm' fontWeight='700'>
            By Store
          </Text>
        </Flex>
      </Flex>
      <ButtonGroup size="sm" isAttached variant="outline" mt={4}>
        <Button onClick={() => setView('monthly')} isActive={view === 'monthly'}>Monthly</Button>
        <Button onClick={() => setView('yearly')} isActive={view === 'yearly'}>Yearly</Button>
      </ButtonGroup>
      <Box h='240px' mt='auto' w='100%'>
        {isLoading ? (
          <Flex justify="center" align="center" h="100%">
            <Spinner />
          </Flex>
        ) : (
          <>
            {view === 'monthly' && (!barChartDataMonthly.length || !barChartDataMonthly[0].data.length) ? (
              <Flex justify="center" align="center" h="100%">
                <Text>No receipts</Text>
              </Flex>
            ) : view === 'yearly' && (!barChartDataYearly.length || !barChartDataYearly[0].data.length) ? (
              <Flex justify="center" align="center" h="100%">
                <Text>No receipts</Text>
              </Flex>
            ) : view === 'monthly' ? (
              <BarChart
                chartData={barChartDataMonthly}
                chartOptions={barChartOptionsMonthly}
              />
            ) : (
              <BarChart
                chartData={barChartDataYearly}
                chartOptions={barChartOptionsYearly}
              />
            )}
          </>
        )}
      </Box>
    </Card>
  );
}

import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import config from 'config';
import StatusMessage from 'components/StatusMessage';


function SignUp() {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('');

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [formData, setFormData] = useState({
    username: '',
    name: '',
    lastname: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus('loading');

    if (!validatePassword(formData.password)) {
      setError('Password must be at least 8 characters long and include an uppercase letter, a special symbol, and a number.');
      return;
    }

    try {
      const response = await axios.post(`${config.API_BASE_URL}/register`, formData);
      console.log(response);
      if (response.status === 201 ) {
        setStatus('success');
        setMessage('Account created successfully! Redirecting...');
        setTimeout(() => {
          history.push('/auth/sign-in');
        }, 2000);
      } else {
        setError('An error occurred, please try again later.');
        setStatus('error');
        setMessage('An error occurred, please try again later.');
      }
    }
    catch (error) {
      if (error.response.status === 400) {
        setError('Username or email already exists.');
        setStatus('error');
        setMessage('Username or email already exists.');
      } else {
        setError('An error occurred, please try again later.');
        setStatus('error');
        setMessage('An error occurred, please try again later.');
      }
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w='100%'
        mx={{ base: 'auto', lg: '0px' }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection='column'
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign Up
          </Heading>
          <Text mb='36px' ms='4px' color={textColorSecondary} fontWeight='400' fontSize='md'>
            Enter your details to create an account!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}
        >
          <Flex align='center' mb='25px'>
            <HSeparator />
            <HSeparator />
          </Flex>
          <form onSubmit={handleSubmit}>
          {status && (
            <StatusMessage
              status={status}
              message={message}
              onClose={() => setStatus(null)}
            />
          )}
            <FormControl>
              <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                Username and Email
              </FormLabel>
              <InputGroup display="flex">
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: '0px', md: '0px' }}
                  type='text'
                  placeholder='Enter your username'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  name='username'
                  value={formData.username}
                  onChange={handleChange}
                  mr='4px'
                />
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: '0px', md: '0px' }}
                  type='email'
                  placeholder='Enter your email'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                />
              </InputGroup>
              <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                First and Last Name (Optional)
              </FormLabel>
              <InputGroup display="flex">
                <Input
                  isRequired={false}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: '0px', md: '0px' }}
                  type='text'
                  placeholder='Enter your first name'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  mr='4px'
                />
                <Input
                  isRequired={false}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: '0px', md: '0px' }}
                  type='text'
                  placeholder='Enter your last name'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  name='lastname'
                  value={formData.lastname}
                  onChange={handleChange}
                />
              </InputGroup>
              <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                Password
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? 'text' : 'password'}
                  variant='auth'
                  name='password'
                  value={formData.password}
                  onChange={handleChange}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                type='submit'
              >
                Sign Up
              </Button>
              {error && (
                <Text color='red.500' fontSize='sm' mt='4px'>
                  {error}
                </Text>
              )}
            </FormControl>
          </form>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'
          >
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Already have an account?
              <NavLink to='/auth/sign-in'>
                <Text color={textColorBrand} as='span' ms='5px' fontWeight='500'>
                  Sign In
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;

import React, { useEffect, useState } from "react";
import { Box, Grid } from "@chakra-ui/react";
import axios from "axios";
import config from "config";

// Custom components
import Banner from "views/user/profile/components/Banner";
import Usage from "views/user/profile/components/Usage";
import Feedback from "views/user/profile/components/Feedback";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";

const Overview = () => {
  const [user, setUser] = useState({});
  const [receipts, setReceipts] = useState([]);

  useEffect(() => {
    const fetchUserAndReceipts = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };

        const [userResponse, receiptsResponse] = await Promise.all([
          axios.get(`${config.API_BASE_URL}/get_user`, { headers }),
          axios.get(`${config.API_BASE_URL}/get_all_receipts`, { headers }),
        ]);

        setUser(userResponse.data);
        setReceipts(receiptsResponse.data.receipts);
      } catch (error) {
        console.error("Error fetching user and receipts:", error);
      }
    };

    fetchUserAndReceipts();
  }, []);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Grid
        templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }}
        templateRows={{ base: 'repeat(3, 1fr)', lg: '1fr' }}
        gap={{ base: '20px', xl: '20px' }}
      >
        <Banner
          gridArea={{ base: '1 / 1 / 2 / 2', lg: '1 / 1 / 2 / 4' }}
          banner={banner}
          avatar={avatar}
          username={user.username}
          firstName={user.name}
          lastName={user.lastname}
          email={user.email}
          created_at={user.created_at}
        />
        <Usage
          gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 1 / 2 / 2' }}
          used={receipts.length}
          total={user.receipts_limit || 0}
        />
        <Feedback
          gridArea={{ base: '3 / 1 / 4 / 2', lg: '1 / 3 / 2 / 4' }}
          minH={{ base: 'auto', lg: '200px', '2xl': '200px' }}
          pe="20px"
          pb={{ base: '100px', lg: '20px' }}
        />
      </Grid>
    </Box>
  );
};

export default Overview;

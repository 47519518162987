import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

const InvalidReceiptsModal = ({ isOpen, onClose, receipts, onEditReceipt }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Invalid Receipts</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Table>
          <Thead>
            <Tr>
              <Th>Merchant Name</Th>
              <Th>Total</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {receipts.filter(receipt => !receipt.validation_status).map((receipt) => (
              <Tr key={receipt.id}>
                <Td>{receipt.merchant_name}</Td>
                <Td>{receipt.total}</Td>
                <Td>
                  <Button size="sm" onClick={() => onEditReceipt(receipt)}>Edit</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default InvalidReceiptsModal;

import React, { useEffect, useMemo, useState } from "react";
import { Box, Flex, Text, Select, Spinner, useColorModeValue, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";

export default function PieCard({ receipts = [], items = [], ...rest }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  //const cardColor = useColorModeValue("white", "navy.700");

  const [pieChartData, setPieChartData] = useState([]);
  const [pieChartOptions, setPieChartOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [timeFrame, setTimeFrame] = useState('month');
  const [legendData, setLegendData] = useState([]);

  const getCurrentWeekRange = () => {
    const now = new Date();
    const start = new Date(now);
    start.setDate(now.getDate() - 7);
    const end = now;
    return { start, end };
  };

  const getCurrentMonthRange = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return { start, end };
  };

  const getCurrentYearRange = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const end = new Date(now.getFullYear(), 11, 31);
    return { start, end };
  };

  const filteredReceipts = useMemo(() => {
    //const now = new Date();
    let result = [];

    if (timeFrame === 'week') {
      const { start, end } = getCurrentWeekRange();
      result = receipts.filter(receipt => new Date(receipt.transaction_date) >= start && new Date(receipt.transaction_date) <= end);
    } else if (timeFrame === 'month') {
      const { start, end } = getCurrentMonthRange();
      result = receipts.filter(receipt => new Date(receipt.transaction_date) >= start && new Date(receipt.transaction_date) <= end);
    } else if (timeFrame === 'year') {
      const { start, end } = getCurrentYearRange();
      result = receipts.filter(receipt => new Date(receipt.transaction_date) >= start && new Date(receipt.transaction_date) <= end);
    }

    return result;
  }, [receipts, timeFrame]);

  useEffect(() => {
    if (filteredReceipts.length > 0 && items.length > 0) {
      const receiptIds = filteredReceipts.map(receipt => receipt.id);
      const filteredItems = items.filter(item => receiptIds.includes(item.receipt_id));

      const categorySpendings = filteredItems.reduce((acc, item) => {
        const category = item.category;
        const total_price = parseFloat(item.total_price) || 0;
        if (!acc[category]) {
          acc[category] = 0;
        }
        acc[category] += total_price;
        return acc;
      }, {});

      const data = Object.entries(categorySpendings).map(([category, total]) => ({
        name: category,
        value: total
      }));

      const chartData = data.map(d => d.value);
      const chartLabels = data.map(d => d.name);

      const totalSpending = chartData.reduce((acc, value) => acc + value, 0);

      const colors = [
        '#4318FF', '#6AD2FF', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6B6B', '#FFD93D', '#6B8E23', '#4682B4', '#D2691E',
        '#9ACD32', '#FF4500', '#32CD32', '#05FCD3', '#4B9C78', '#BD8771', '#BD42A6', '#174D31', '#E3A6D8', '#998B4B'
      ];

      const selectedColors = colors.slice(0, chartLabels.length);

      const chartOptions = {
        labels: chartLabels,
        colors: selectedColors,
        chart: {
          width: "50px",
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        hover: { mode: null },
        plotOptions: {
          donut: {
            expandOnClick: false,
            donut: {
              labels: {
                show: false,
              },
            },
          },
        },
        fill: {
          colors: selectedColors,
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              const percentage = (value / totalSpending * 100).toFixed(2);
              return `${percentage}%`;
            },
            title: {
              formatter: function (seriesName) {
                return seriesName;
              }
            }
          }
        },
      };

      setPieChartData(chartData);
      setPieChartOptions(chartOptions);
      setLegendData(data.map((item, index) => ({ ...item, color: selectedColors[index] })));
      setLoading(false);
    } else {
      setPieChartData([]);
      setPieChartOptions({});
      setLegendData([]);
      setLoading(false);
    }
  }, [filteredReceipts, items]);

  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='8px'>
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
          Spent by Category
        </Text>
        <Select
          fontSize='sm'
          variant='subtle'
          value={timeFrame}
          width='unset'
          fontWeight='700'
          onChange={(e) => { setLoading(true); setTimeFrame(e.target.value); }}>
          <option value='week'>This week</option>
          <option value='month'>This month</option>
          <option value='year'>This year</option>
        </Select>
      </Flex>
  
      {loading ? (
        <Spinner size="xl" />
      ) : pieChartData.length > 0 ? (
        <Flex direction={{ base: 'column', xl: 'row' }} w='100%' alignItems="center" justifyContent="center">
          <Box w={{ base: '100%', xl: '50%' }} h={{ base: '400px', xl: 'auto' }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box w='100%' h={{ base: '350px', xl: '350px' }} display="flex" justifyContent="center" alignItems="center">
              <PieChart
                h='100%'
                w='100%'
                chartData={pieChartData}
                chartOptions={pieChartOptions}
              />
            </Box>
          </Box>
          <Box w={{ base: '100%', xl: '50%' }} mt={{ base: '20px', xl: '0' }} ml={{ base: '0', xl: '10px' }}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Category</Th>
                  <Th isNumeric>Total Spent</Th>
                </Tr>
              </Thead>
              <Tbody>
                {legendData.map((item, index) => (
                  <Tr key={index} style={{ marginTop: '4px', marginBottom: '4px' }}>
                    <Td style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                      <Flex alignItems="center">
                        <Box
                          w='12px'
                          h='12px'
                          bg={item.color}
                          borderRadius='50%'
                          mr='8px'
                        ></Box>
                        {item.name}
                      </Flex>
                    </Td>
                    <Td isNumeric style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                      ${item.value.toFixed(2)}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Flex>
      ) : (
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
          No Receipts
        </Text>
      )}
    </Card>
  );
}
